class Opal_Give {
    constructor() {
        $(document).on('click', '[data-toogle="fundor-video"]', (event) => {
            this.video(event);
        })
        $(document).on('click', '[data-toogle="fundor-gallery"]', (event) => {
            this.gallery(event);
        })

        $(document).on('click', '.delete-campaign', (event) => {
            var result = confirm(fundor.confirm);
            if (result) {
                this.delete_post(event);
            }else {
                event.preventDefault();
            }
        })
        this.gallery_single();
        this.single_tabs();
    }

    video(event) {
        event.preventDefault();
        let $button = $(event.currentTarget);
        if ($button.data('loaded')) {
            $button.find('.ajax-fundor-video a').trigger('click');
        } else {
            $.ajax({
                method: 'POST',
                url: fundor.ajaxurl,
                data: {
                    action: 'get_fundor_video',
                    give_id: $button.data('fundor-id'),
                },
                beforeSend: () => {
                    $button.addClass('loading');
                },
                success: (response) => {
                    $button.data('loaded', true);
                    if (response) {
                        $button.append(this.renderVideoHTML(response));
                        let $video = $button.find('.ajax-fundor-video a');
                        $video.magnificPopup({
                            disableOn: 700,
                            type: 'iframe',
                            mainClass: 'mfp-fade',
                            removalDelay: 160,
                            preloader: false,
                            fixedContentPos: false
                        });
                        $video.trigger('click');
                    }
                    $button.removeClass('loading');
                }
            })
        }
    }

    gallery(event) {
        event.preventDefault();
        let $button = $(event.currentTarget);
        if ($button.data('loaded')) {
            $button.find('.ajax-fundor-gallery a:first-child').trigger('click');
        } else {
            $.ajax({
                method: 'POST',
                url: fundor.ajaxurl,
                data: {
                    action: 'get_fundor_gallery',
                    give_id: $button.data('fundor-id'),
                },
                beforeSend: () => {
                    $button.addClass('loading');
                },
                success: (response) => {
                    $button.data('loaded', true);
                    if (response) {
                        $button.append(this.renderGalleryHTML(response));
                        let $gallery = $button.find('.ajax-fundor-gallery');
                        $gallery.magnificPopup({
                            delegate: 'a', // the selector for gallery item
                            type: 'image',
                            gallery: {
                                enabled: true
                            }
                        });
                        $gallery.find('>a:first-child').trigger('click');
                    }
                    $button.removeClass('loading');
                }
            })
        }
    }

    delete_post(event) {
        event.preventDefault();
        let $button = $(event.currentTarget),
            $parent = $button.closest('.profile-campaign__item');
        if ($button.data('form_id')) {
            $.ajax({
                method: 'POST',
                url: fundor.ajaxurl,
                data: {
                    action: 'opal_give_delete_campaign',
                    form_id: $button.data('form_id'),
                },
                beforeSend: () => {
                    $button.addClass('loading');
                },
                success: (response) => {
                    if (response) {
                        $parent.remove();
                    }
                }
            })
        }

    }

    renderVideoHTML(video) {
        return `<div class="ajax-fundor-video"><a href="${video}"></a></div>`;
    }

    renderGalleryHTML(listImages) {
        let html = `<div class="ajax-fundor-gallery">`
        for (let i in listImages) {
            html += `<a href="${listImages[i]}"><img src="${listImages[i]}"></a>`;
        }
        html += '</div>';
        return html;
    }

    gallery_single() {
        $('.single-give_forms .gallery').magnificPopup({
            delegate: 'a',
            type: 'image',
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0, 1]
            },
            image: {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                titleSrc: function (item) {
                    return item.el.attr('title');
                }
            }
        });
    }

    single_tabs() {
        let $tabs = $("#give-form-single-tab");
        if ($tabs.length > 0) {
            $tabs.tabs();
        }
    }
}

$(document).ready(function () {
    new Opal_Give();
});

var Barometer = new Object(function () {

    var $barometers = $('.barometer'),

        isInView = function ($el) {
            var docViewTop = $(window).scrollTop(),
                docViewBottom = docViewTop + $(window).height(),
                elemTop = $el.offset().top,
                elemBottom = elemTop + $el.height();

            return ((elemBottom >= docViewTop) && (elemTop <= docViewBottom)
                && (elemBottom <= docViewBottom) && (elemTop >= docViewTop));
        },

        customArc = function (xloc, yloc, value, total, R) {
            var alpha = 360 / total * value,
                a = (90 - alpha) * Math.PI / 180,
                x = xloc + R * Math.cos(a),
                y = yloc - R * Math.sin(a),
                path;

            if (total == value) {
                path = [
                    ["M", xloc, yloc - R],
                    ["A", R, R, 0, 1, 1, xloc - 0.01, yloc - R]
                ];
            } else {
                path = [
                    ["M", xloc, yloc - R],
                    ["A", R, R, 0, +(alpha > 180), 1, x, y]
                ];
            }
            return {
                path: path
            };
        },

        // Draws a barometer
        drawBarometer = function ($barometer, r, width, height, progress_val) {
            var progress;

            // Draw the percentage filled arc
            if (progress_val > 0) {
                progress = r.path().attr({
                    stroke: $barometer.data('progress-stroke'),
                    'stroke-width': $barometer.data('strokewidth') + 1,
                    arc: [width / 2, height / 2, 0, 100, (width / 2) - 8]
                });

                // Animate it
                progress.animate({
                    arc: [width / 2, height / 2, progress_val, 100, (width / 2) - 8]
                }, 1500, "easeInOut", function () {
                    $barometer.find('span').animate({opacity: 1}, 300, 'linear');
                });
            }
        },

        // Init barometer
        initBarometer = function ($barometer) {
            var width = $barometer.data('width'),
                height = $barometer.data('height'),
                r = Raphael($barometer[0], width, height),
                drawn = false,
                progress_val = $barometer.data('progress') > 100 ? 100 : $barometer.data('progress'),
                circle;

            // @see http://stackoverflow.com/questions/5061318/drawing-centered-arcs-in-raphael-js
            r.customAttributes.arc = customArc;

            // Draw the main circle
            circle = r.path().attr({
                stroke: $barometer.data('stroke'),
                'stroke-width': $barometer.data('strokewidth'),
                arc: [width / 2, height / 2, 0, 100, (width / 2) - 8]
            });

            // Fill the main circle
            $barometer.parent().addClass('barometer-added');
            circle.animate({arc: [width / 2, height / 2, 100, 100, (width / 2) - 8]}, 1000, function () {
                if (progress_val === 0) {
                    $barometer.find('span').animate({opacity: 1}, 500, 'linear');
                }
            });

            if (isInView($barometer)) {
                drawBarometer($barometer, r, width, height, progress_val);

                drawn = true;
            } else {
                $(window).scroll(function () {
                    if (drawn === false && isInView($barometer)) {
                        drawBarometer($barometer, r, width, height, progress_val);

                        drawn = true;
                    }
                });
            }
        };

    return {

        init: function () {
            $barometers.each(function () {
                initBarometer($(this));
            });
        },

        getBarometers: function () {
            return $barometers;
        },

        _drawBarometer: function (barometer, r, width, height, progress_val) {
            drawBarometer(barometer, r, width, height, progress_val);
        }
    }

})();

$(window).load(function () {
    Barometer.init();
})